<template>
  <div class="home">
    <ui-loading-overlay />
  </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "#imports";
import UiLoadingOverlay from "@/components/global/ui/UiLoadingOverlay.vue";

const authStore = useAuthStore();
const router = useRouter();

onMounted(async () => {
  await authStore.loadUser();
  if (authStore.user) {
    router.push("/account");
  } else {
    router.push("/login");
  }
});
</script>
